import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicationSignatureTree,
  DatasourceEnvironments,
  ENVIRONMENT_STAGING,
  IApplicationV2Dto,
} from "@superblocksteam/shared";
import { Draft } from "immer";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { createPageSuccess } from "legacy/actions/pageActions";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "legacy/constants/ReduxActionConstants";
import { ERROR_MESSAGE_RESET_APPLICATION } from "legacy/constants/messages";
import {
  updateApplication,
  fetchApplication,
  fetchApplicationSuccess,
  updateNonVersionedApplicationSettings,
  updateApplicationMetadata,
  updateApplicationSettings,
} from "store/slices/application/applicationActions";
import { deployCommitSaga } from "store/slices/deployments";
import { fastClone } from "utils/clone";
import { sendErrorUINotification } from "utils/notification";
import { migrateCustomComponentsConfig } from "./customComponentMigrations";

interface ApplicationsReduxState {
  isSavingAppName: boolean;
  isSavingApplication: boolean; //indicating PUT /applications endpoint is being called
  isSavingApplicationFailed: boolean;
  isSavingApplicationStale: boolean;
  isFetchingApplication: boolean;
  isChangingViewAccess: boolean;
  currentApplication?: ReturnType<typeof fetchApplicationSuccess>["payload"];
  currentApplicationSignatureTree?: ApplicationSignatureTree;
  environment: string;
  datasourcesToAuthenticate: string[];
  isIframeLoaded: boolean;
  commitId?: string;
}

const initialState: ApplicationsReduxState = {
  currentApplication: undefined,
  // Show initial loading spinner
  isSavingAppName: false,
  isSavingApplication: false,
  isSavingApplicationFailed: false,
  isSavingApplicationStale: false,
  isFetchingApplication: false,
  isChangingViewAccess: false,
  datasourcesToAuthenticate: [],
  environment: ENVIRONMENT_STAGING,
  isIframeLoaded: false,
};

function setSavingStatus(
  state: Draft<ApplicationsReduxState>,
  updates: Partial<IApplicationV2Dto>,
): void {
  state.isSavingAppName = Boolean(updates.name);
  state.isSavingApplication = true;
  state.isSavingApplicationFailed = false;
}

export const applicationSlice = createSlice({
  name: "wrapper",
  initialState: initialState as ApplicationsReduxState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(stopEvaluation, () => initialState)
      .addCase(
        ReduxActionTypes.APP_FRAME_LOADED,
        (state: ApplicationsReduxState) => {
          return { ...state, isIframeLoaded: true };
        },
      )
      .addCase(fetchApplication, (state: ApplicationsReduxState, action) => ({
        ...state,
        isFetchingApplication: true,
        commitId: action.payload.commitId,
      }))
      .addCase(
        fetchApplicationSuccess,
        (state: ApplicationsReduxState, action) => {
          const application = action.payload;

          if (
            application?.settings?.cliVersion &&
            application?.settings?.registeredComponents
          ) {
            const cliVersion = application?.settings?.cliVersion;
            const oldComponents = application?.settings?.registeredComponents;

            const newComponents = migrateCustomComponentsConfig(oldComponents, {
              previousVersion: cliVersion,
            });
            application.settings.registeredComponents = newComponents;
          }

          state.currentApplication = application;
          state.isFetchingApplication = false;
        },
      )
      .addCase(
        ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<
            { tree: ApplicationSignatureTree },
            typeof ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE
          >,
        ) => {
          return {
            ...state,
            currentApplicationSignatureTree: action.payload.tree,
          };
        },
      )
      .addCase(
        deployCommitSaga.success.type,
        (
          state: ApplicationsReduxState,
          action: ReduxAction<{ commitId: string }>,
        ) => {
          return {
            ...state,
            currentApplication: state.currentApplication && {
              ...state.currentApplication,
              isDeployed: true,
              deployedCommitId: action.payload.commitId,
            },
          };
        },
      )
      .addCase(
        ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<
            string,
            typeof ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE
          >,
        ) => ({
          ...state,
          currentApplication: state.currentApplication && {
            ...state.currentApplication,
            name: action.payload,
          },
        }),
      )
      .addCase(
        ReduxActionTypes.CURRENT_APPLICATION_IS_PUBLIC_UPDATE,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<
            boolean,
            typeof ReduxActionTypes.CURRENT_APPLICATION_IS_PUBLIC_UPDATE
          >,
        ) => ({
          ...state,
          currentApplication: state.currentApplication && {
            ...state.currentApplication,
            isPublic: action.payload,
          },
        }),
      )
      .addCase(
        ReduxActionTypes.CURRENT_APPLICATION_ENVIRONMENT_UPDATE,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<
            DatasourceEnvironments,
            typeof ReduxActionTypes.CURRENT_APPLICATION_ENVIRONMENT_UPDATE
          >,
        ) => ({
          ...state,
          currentApplication: state.currentApplication && {
            ...state.currentApplication,
            environment: action.payload,
          },
        }),
      )
      .addCase(
        updateApplicationSettings,
        (state: ApplicationsReduxState, action) => {
          const settings = fastClone(action.payload);

          if (settings?.cliVersion && settings?.registeredComponents) {
            const cliVersion = settings?.cliVersion;
            const oldComponents = settings?.registeredComponents;

            const newComponents = migrateCustomComponentsConfig(oldComponents, {
              previousVersion: cliVersion,
            });
            settings.registeredComponents = newComponents;
          }
          if (state.currentApplication) {
            state.currentApplication = {
              ...state.currentApplication,
              settings: settings,
            };
          }
        },
      )
      .addCase(
        ReduxActionTypes.REQUEST_DATASOURCE_AUTH,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<
            { datasourceId: string },
            typeof ReduxActionTypes.REQUEST_DATASOURCE_AUTH
          >,
        ) => {
          return {
            ...state,
            datasourcesToAuthenticate: state.datasourcesToAuthenticate.includes(
              action.payload.datasourceId,
            )
              ? state.datasourcesToAuthenticate
              : [
                  ...state.datasourcesToAuthenticate,
                  action.payload.datasourceId,
                ],
          };
        },
      )
      .addCase(
        ReduxActionTypes.UPDATE_ENVIRONMENT,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<
            { environment: string },
            typeof ReduxActionTypes.UPDATE_ENVIRONMENT
          >,
        ) => {
          return {
            ...state,
            environment: action.payload.environment,
          };
        },
      )
      .addCase(
        ReduxActionTypes.AUTH_FINISHED,
        (state: ApplicationsReduxState) => {
          return {
            ...state,
            datasourcesToAuthenticate: state.datasourcesToAuthenticate.slice(1),
          };
        },
      )
      .addCase(updateApplication, (state: ApplicationsReduxState, action) => {
        const { id, branch, ...rest } = action.payload;
        setSavingStatus(state, rest);
        if (state.currentApplication) {
          state.currentApplication.configuration = rest.configuration;

          if (rest.settings !== undefined) {
            state.currentApplication.settings = rest.settings;
          }
        }
      })
      .addCase(
        updateNonVersionedApplicationSettings,
        (state: ApplicationsReduxState, action) => {
          // the settings state update is handled in ReduxActionTypes.CURRENT_APPLICATION_SETTINGS_UPDATE
          // calling this to update saving status
          setSavingStatus(state, {});
          if (state.currentApplication) {
            state.currentApplication.settings = action.payload.settings;
          }
        },
      )
      .addCase(updateApplicationMetadata, (state, action) => {
        const { id, ...rest } = action.payload;
        setSavingStatus(state, { name: rest.name });
        if (state.currentApplication) {
          state.currentApplication = {
            ...state.currentApplication,
            ...rest,
          };
        }
      })
      .addCase(
        ReduxActionTypes.UPDATE_APPLICATION_SUCCESS,
        (state: ApplicationsReduxState) => {
          return {
            ...state,
            isSavingAppName: false,
            isSavingApplication: false,
            isSavingApplicationFailed: false,
          };
        },
      )
      .addCase(
        ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
        (
          state: ApplicationsReduxState,
          action: PayloadAction<{ error: any }>,
        ) => {
          return {
            ...state,
            isSavingAppName: false,
            isSavingApplication: false,
            isSavingApplicationFailed: true,
            isSavingApplicationStale: action.payload.error.code === 409,
          };
        },
      )
      .addCase(
        ReduxActionErrorTypes.RESET_APPLICATION_ERROR,
        (state: ApplicationsReduxState) => {
          sendErrorUINotification({
            message: ERROR_MESSAGE_RESET_APPLICATION,
          });
          return {
            ...state,
          };
        },
      )
      .addCase(createPageSuccess, (state: ApplicationsReduxState, action) => {
        if (!state.currentApplication) return state;

        return {
          ...state,
          currentApplication: {
            ...state.currentApplication,
            configuration: action.payload.configuration,
          },
        };
      });
  },
});
