import {
  DatasourceMetadataDto,
  IntegrationDto,
  IntegrationKind,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";
import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";

export type DatasourceMeta = {
  organizationId?: string;
  metadata?: DatasourceMetadataDto;
};

export type DatasourceDtoViews = {
  integration?: SupersetIntegrationDto;
  // TODO(multipage): remove this once we can filter supersets by type
  full?: IntegrationDto;
  kind?: IntegrationKind;
};

type DatasourceState = EntitiesStateWithMeta<
  DatasourceDtoViews,
  DatasourceMeta
>;

export default new Slice<DatasourceState>("datasources", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
});
