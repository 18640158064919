import generate from "nanoid/generate";

const ALPHANUMERIC = "1234567890abcdefghijklmnopqrstuvwxyz";
// const ALPHABET = "abcdefghijklmnopqrstuvwxyz";

export const generateReactKey = ({
  prefix = "",
}: { prefix?: string } = {}): string => {
  return prefix + generate(ALPHANUMERIC, 10);
};

// Before you change how this works
// This className is used for the following:
// 1. Resize bounds
// 2. Property pane reference for positioning
// superblocks_widget_0 is also used for setting background color of canvas root
export const generateClassName = (seed?: string) => {
  return `superblocks_widget_${seed}`;
};

export const generateDropTargetElementId = (parentWidgetId: string) =>
  `drop-target-${parentWidgetId}`;

export const generateSectionComponentId = (sectionWidgetId: string) =>
  `section-widget-${sectionWidgetId}`;

export const generateCanvasSelectionComponentId = (canvasWidgetId: string) =>
  `canvas-selection-${canvasWidgetId}`;

export const getCanvasClassName = () => "canvas";

export const getNearestParentCanvas = (el: Element | null) => {
  const canvasQuerySelector = `.${getCanvasClassName()}`;
  if (el) return el.closest(canvasQuerySelector);
  return null;
};
