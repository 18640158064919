import * as Sentry from "@sentry/react";
import { get } from "lodash";
import { all, call, put, race, take } from "redux-saga/effects";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import {
  ReduxActionType,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";

import { getStore } from "store/dynamic";
import { rejectById, resolveById } from "store/utils/resolveIdSingleton";
import { fastClone } from "utils/clone";
import { isEmbeddedBySuperblocksFirstParty } from "utils/iframe";
import logger, { getUIErrorTypeByCode } from "utils/logger";

import {
  Action,
  ActionDefinition,
  ActionDefinitionBase,
  ActionDefinitionWithCallId,
  ActionDefinitionWithMeta,
  defineAction,
  defineActionWithCallId,
  defineActionWithMeta,
  isPayloadAction,
  isPayloadActionWithMeta,
  PayloadAction,
  PayloadActionWithCallId,
} from "./action";
import { GeneratorReturnType, HttpError } from "./types";

type SagaGenerator<TArgument, TEffect, TReturn, TNext> = (
  arg: TArgument,
  callId: number,
) => Generator<TEffect, TReturn, TNext>;

type SagaFunction<
  TArgument,
  TGenerator extends SagaGenerator<TArgument, unknown, any, any>,
> = TGenerator extends SagaGenerator<
  TArgument,
  infer TEffect,
  infer TReturn,
  infer TNext
>
  ? SagaGenerator<TArgument, TEffect, TReturn, TNext>
  : TGenerator;

export type SagaResult<TDefinition extends SagaDefinition<any, any>> =
  TDefinition extends SagaDefinition<any, infer TResult> ? TResult : unknown;

export type SagaReturnValue<
  TDefinition extends SagaGenerator<any, any, any, any>,
> = GeneratorReturnType<TDefinition>;

export type SagaPayload<TDefinition extends SagaDefinition<any, any>> =
  TDefinition extends SagaDefinition<infer TPayload, any> ? TPayload : unknown;

type SagaPayloadValue<
  TReturn,
  TGenerator extends SagaGenerator<any, any, TReturn, any>,
> = TGenerator extends SagaGenerator<infer TPayload, any, TReturn, any>
  ? TPayload
  : unknown;

type SagaCallbackPayload<TReturn> = {
  resolve?: (result: TReturn) => void;
};

interface SagaCall<TPayload = unknown> {
  keySelector?: (payload: TPayload, callId?: number) => string;
  start: PayloadActionWithCallId<TPayload>;
  error: string;
  success: string;
  cancel?: string;
}

interface SimpleSagaCall {
  start: Action;
  error: string;
  success: string;
  cancel?: string;
}

export enum SagaType {
  Every = "Every",
  Latest = "Latest",
  Leading = "Leading",
  Throttled = "Throttled",
  Debounced = "Debounced",
}

export interface SagaActionMeta<TPayload> {
  args: TPayload;
  callId: number;
}

interface SagaDefinition<TPayload, TResult> {
  start: ActionDefinitionWithCallId<TPayload>;
  success: ActionDefinitionWithMeta<TResult, SagaActionMeta<TPayload>>;
  error: ActionDefinitionWithMeta<Error, SagaActionMeta<TPayload>>;
  cancel: ActionDefinition<TPayload>;
  apply: (payload: TPayload) => SagaCall<TPayload>;

  store: ActionDefinition<TPayload>;
  setStore: (result: TResult) => Generator;
}

export interface FullSagaDefinition<TPayload, TResult>
  extends SagaDefinition<TPayload, TResult> {
  name: string;
  root: (action: PayloadActionWithCallId<TPayload>) => Generator;
  type: SagaType;
  delay?: number;
  triggers: string[];
  keySelector?: (payload: TPayload, callId?: number) => string;
}

interface SagaOptions<TPayload> {
  sliceName?: string;
  type?: SagaType;
  delay?: number;
  // the delay will not work if saga is triggered by triggerOn actions, since the reducer is called directly instead of dispatching a start action
  triggerOn?: ActionDefinitionBase<TPayload> | ActionDefinitionBase<TPayload>[];
  keySelector?: (payload: TPayload, callId?: number) => string;
  /** Creates a `keykeySelector` that generates a unique key for each call of the saga */
  autoGenerateUniqueKey?: boolean;
  cancelledBy?: (ActionDefinitionBase<TPayload> | ReduxActionType | string)[];
}

export function createSaga<
  TGenerator extends SagaGenerator<any, unknown, any, any>,
  TResult extends SagaReturnValue<TGenerator>,
  TPayload extends SagaPayloadValue<TResult, TGenerator>,
  TFullPayload extends TPayload & SagaCallbackPayload<TResult>,
>(
  saga: SagaFunction<TPayload, TGenerator>,
  sagaName: string,
  options?: SagaOptions<TPayload>,
): FullSagaDefinition<TFullPayload, TResult> {
  const name = options?.sliceName
    ? `${options.sliceName}->${sagaName}`
    : sagaName;

  let keySelector = options?.keySelector;
  if (options?.autoGenerateUniqueKey) {
    keySelector = (payload, sagaCallId) => `${name}-${sagaCallId ?? 0}`;
  }

  const startAction = defineActionWithCallId<TFullPayload>(name, "start");
  const errorAction = defineActionWithMeta<Error, SagaActionMeta<TPayload>>(
    name,
    "error",
  );
  const successAction = defineActionWithMeta<TResult, SagaActionMeta<TPayload>>(
    name,
    "success",
  );
  const cancelAction = defineAction<TResult>(name, "cancel");
  const storeAction = defineAction<TResult>(name, "applyStoreValue");

  function* applyStoreValue(result: TResult) {
    yield put(storeAction.create(result));
  }

  function* root({
    payload: originalPayload,
    callId,
  }: PayloadActionWithCallId<TFullPayload>) {
    // apis, specifically for control flow, deliberately mutate the payload. this is against all recommended
    // patterns for Redux but requires a bigger refactor to change
    const payload =
      name.startsWith("apis") && !isEmbeddedBySuperblocksFirstParty()
        ? fastClone(originalPayload)
        : { ...originalPayload };
    try {
      if (!originalPayload) {
        throw new Error(`${name}: payload is missing`);
      }
      // resolveId should never be saved, it's only for the useSaga helper
      const resolveId = originalPayload.resolveId;
      delete payload.resolveId;

      const result: { success: TResult; cancel: any } = yield race({
        success: call(
          saga as (payload: TFullPayload, callId: number) => Generator,
          payload,
          callId,
        ),
        cancel: take((action: Action) => {
          if (
            (action.type === cancelAction.type &&
              (!keySelector ||
                (keySelector &&
                  (action as PayloadAction<TFullPayload>).payload &&
                  payload &&
                  // TODO: should we pass callId to keySelector? it probably fine to not pass it since we
                  // probably want to cancel all calls of the this saga
                  keySelector(
                    (action as PayloadAction<TFullPayload>).payload,
                  ) === keySelector(payload)))) ||
            options?.cancelledBy?.includes(action.type)
          ) {
            return true;
          }
          return false;
        }),
      });
      if (result.cancel) {
        // Cancellation is from either:
        // a. User cancellation
        // b. Another source such as navigating away
        // To handle case B we need to explicitly trigger the cancel action
        if (result.cancel.type !== cancelAction.type) {
          yield put(cancelAction.create(payload));
        }
        if (resolveId) {
          resolveById(resolveId, result.cancel);
        }
      } else {
        yield put(
          successAction.create(result.success, { args: payload, callId }),
        );
        if (resolveId) {
          resolveById(resolveId, result.success);
        }
      }
    } catch (e) {
      logger.error(e);
      Sentry.captureException(e);
      if (e instanceof HttpError && e.critical) {
        const code = get(e, "code", ERROR_CODES.SERVER_ERROR);
        logger.error(
          `Request has failed
  Response Status: ${code}
  Response Status Text: ${e.message}`,
          {
            superblocks_ui_error_type: getUIErrorTypeByCode(code as number),
            superblocks_ui_error_code: code as number,
          },
        );
        yield put({
          type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
          payload: {
            code,
          },
        });
      }

      if (e instanceof Error) {
        yield put(errorAction.create(e, { args: payload, callId }));
      }
      const resolveId = originalPayload.resolveId;
      if (payload.throwErrorOutsideSaga && resolveId) {
        rejectById(resolveId, e);
      }
    }
  }

  const triggers = [startAction.type];

  if (options?.triggerOn) {
    if ("type" in options.triggerOn) {
      triggers.push(options.triggerOn.type);
    } else {
      triggers.push(...options.triggerOn.map((trigger) => trigger.type));
    }
  }

  return {
    triggers,
    error: errorAction,
    start: startAction,
    success: successAction,
    cancel: cancelAction,
    store: storeAction,
    name,
    root,
    setStore: applyStoreValue,
    type: options?.type ?? SagaType.Every,
    delay: options?.delay,
    keySelector,
    apply: (payload) => {
      return {
        keySelector,
        start: startAction.create(payload),
        error: errorAction.type,
        success: successAction.type,
        cancel: cancelAction.type,
      };
    },
  };
}

interface SagaCallOptions {
  throwOnFailure?: boolean;
}

export function* forkSagas(calls: (SagaCall | SimpleSagaCall)[]) {
  yield all(calls.map((call) => put(call.start)));
}

export function* callSagas(
  calls: (SagaCall | SimpleSagaCall)[],
  { throwOnFailure }: SagaCallOptions = {},
) {
  yield all(calls.map((call) => put(call.start)));
  const result: {
    success?: PayloadAction<unknown>[];
    failure?: PayloadAction<Error>;
    cancelled?: PayloadAction<unknown>;
  } = yield race({
    success: all(
      calls.map((call) => {
        if ("keySelector" in call) {
          return take((action: Action) => {
            if (action.type === call.success) {
              if (call.keySelector) {
                return (
                  isPayloadActionWithMeta(action) &&
                  call.keySelector(
                    action.meta.args ?? {},
                    action.meta.callId,
                  ) ===
                    call.keySelector(
                      call.start.payload ?? {},
                      call.start.callId,
                    )
                );
              }
              return true;
            }

            return false;
          });
        }
        return take(call.success);
      }),
    ),
    // A single failure stops the whole thing
    failure: take((action: Action) => {
      for (const call of calls) {
        if (action.type === call.error) {
          if (!("keySelector" in call)) {
            // Skip early if we find a matching error
            return true;
          }
          if (call.keySelector) {
            const isMatchingFailure =
              isPayloadActionWithMeta(action) &&
              call.keySelector(action.meta.args ?? {}, action.meta.callId) ===
                call.keySelector(call.start.payload ?? {}, call.start.callId);
            // Don't stop execution unless it's a match
            if (isMatchingFailure) return true;
          }
        }
      }

      return false;
    }),
    // A single cancellation stops the whole thing
    cancelled: take((action: Action) => {
      for (const call of calls) {
        if (action.type === call.cancel) {
          if (!("keySelector" in call)) {
            // Skip early if we find a matching error
            return true;
          }
          if (call.keySelector) {
            const isMatchingCancel =
              isPayloadAction(action) &&
              // TODO: should we pass callId to keySelector? it probably fine to not pass it since we
              // are canceling all calls of the this saga
              call.keySelector(action.payload ?? {}) ===
                call.keySelector(call.start.payload ?? {});
            // Don't stop execution unless it's a match
            if (isMatchingCancel) return true;
          }
        }
      }

      return false;
    }),
  });

  if (result.failure && throwOnFailure) {
    throw result.failure.payload;
  }

  if (result.cancelled && throwOnFailure) {
    throw result.cancelled.payload;
  }

  if (result.success) {
    return result.success.map(
      (action: PayloadAction<unknown>) => action.payload,
    );
  }

  return [];
}

export const nonSagaFunctions = {
  forkSaga: (call: SagaCall | SimpleSagaCall) => {
    const store = getStore();
    store.dispatch(call.start as any);
  },
};
