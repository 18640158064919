import { createReducer } from "@reduxjs/toolkit";
import { AccessMode, SurveyToShow } from "@superblocksteam/shared";
import _ from "lodash";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { User } from "legacy/constants/userConstants";

const initialState: UsersReduxState = {
  loadingStates: {
    fetchingUsers: false,
    fetchingUser: false,
  },
  list: [],
  users: [],
  error: "",
  current: undefined,
  currentUser: undefined,
  accessMode: AccessMode.VISITOR,
};

const usersReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(ReduxActionTypes.FETCH_USER_INIT, (state: UsersReduxState) => ({
      ...state,
      loadingStates: {
        ...state.loadingStates,
        fetchingUser: true,
      },
    }))
    .addCase(
      ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
      (
        state: UsersReduxState,
        action: ReduxAction<
          User,
          typeof ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS
        >,
      ) => {
        const users = [...state.users];
        const userIndex = _.findIndex(users, {
          username: action.payload.username,
        });
        if (userIndex > -1) {
          users[userIndex] = action.payload;
        } else {
          users.push(action.payload);
        }
        return {
          ...state,
          loadingStates: {
            ...state.loadingStates,
            fetchingUser: false,
          },
          users,
          currentUser: action.payload,
        };
      },
    )
    .addCase(
      ReduxActionErrorTypes.FETCH_USER_DETAILS_ERROR,
      (state: UsersReduxState, action: ReduxAction<{ error: string }>) => ({
        ...initialState,
        error: action.payload.error,
      }),
    )
    .addCase(
      ReduxActionTypes.SET_USER_MODE,
      (
        state: UsersReduxState,
        action: ReduxAction<AccessMode, typeof ReduxActionTypes.SET_USER_MODE>,
      ) => ({
        ...state,
        accessMode: action.payload,
      }),
    )
    .addCase(
      ReduxActionTypes.SUBMIT_SURVEY,
      (
        state: UsersReduxState,
        action: ReduxAction<
          { surveyToShow: SurveyToShow },
          typeof ReduxActionTypes.SUBMIT_SURVEY
        >,
      ) => ({
        ...state,
        currentUser: state.currentUser && {
          ...state.currentUser,
          surveyToShow: action.payload.surveyToShow,
        },
      }),
    ),
);

export interface UsersReduxState {
  current?: User;
  list: User[];
  loadingStates: {
    fetchingUser: boolean;
    fetchingUsers: boolean;
  };
  users: User[];
  currentUser?: User;
  error: string;
  accessMode: AccessMode;
}

export default usersReducer;
