import {
  SESSION_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE,
  SESSION_STORAGE_QUOTA_EXCEEDED_MESSAGE,
} from "legacy/constants/messages";
import logger from "utils/logger";
import { sendErrorUINotification } from "utils/notification";

export enum SessionStorageKey {
  HOME_SEARCH = "home_entity_search",
  PROP_PANE_WIDTH = "prop_pane_width",
  LEFT_PANE_PINNED = "left_pane_pinned",
  ACTIVE_LEFT_PANE = "active_left_pane",
  FEATURE_OVERRIDES = "feature_overrides",
  GITHUB_INSTALL_AUTO_TRIGGERED = "github_install_auto_triggered",
  ENTITY_TO_CURRENT_BRANCH = "entity_to_current_branch",
  CONTROL_FLOW_PROFILE_BASED_OVERRIDE = "control_flow_profile_based_override",
  CONTROL_FLOW_PROFILE_ID_OVERRIDE = "control_flow_profile_id_override",
  REFRESH_PREVIOUS_VERSION = "page_refresh_previous_version",
  EDITOR_PREVIOUS_ROUTES = "editor_previous_routes",
  EDITOR_PREVIOUS_QUERY_PARAMS = "editor_previous_query_params",
}

const getSessionStorage = () => {
  let storage: Storage | null = null;
  try {
    storage = window.sessionStorage;
  } catch (e) {
    logger.warn(`session storage disabled: ${e}`);
  }

  const handleError = (e: Error) => {
    let message;
    if (e.name === "QuotaExceededError") {
      message = SESSION_STORAGE_QUOTA_EXCEEDED_MESSAGE;
    } else if (e.name === "NS_ERROR_FILE_NO_DEVICE_SPACE") {
      message = SESSION_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE;
    }

    if (message) {
      sendErrorUINotification({ message });
    } else {
      throw e;
    }
  };

  const getItem = (key: SessionStorageKey): string | null => {
    if (!storage) {
      return null;
    }
    try {
      return storage.getItem(key);
    } catch (e) {
      handleError(e as Error);
    }
    return null;
  };

  const getFloat = (key: SessionStorageKey): number | null => {
    const val = sessionStorage.getItem(key);
    if (val === null) {
      return val;
    }
    const parsed = parseFloat(val);
    return isNaN(parsed) ? null : parsed;
  };

  const getInt = (key: SessionStorageKey): number | null => {
    const val = sessionStorage.getItem(key);
    if (val === null) {
      return val;
    }
    const parsed = parseInt(val);
    return isNaN(parsed) ? null : parsed;
  };

  const getBoolean = (key: SessionStorageKey): boolean | null => {
    const val = sessionStorage.getItem(key);
    if (val === "true") return true;
    if (val === "false") return false;
    return null;
  };

  const setItem = (key: SessionStorageKey, value: string) => {
    if (!storage) {
      return;
    }
    try {
      storage.setItem(key, value);
    } catch (e) {
      handleError(e as Error);
    }
  };

  const removeItem = (key: SessionStorageKey) => {
    if (!storage) {
      return;
    }
    try {
      storage.removeItem(key);
    } catch (e) {
      handleError(e as Error);
    }
  };

  const isSupported = () => !!window.sessionStorage;

  return {
    getItem,
    getInt,
    getFloat,
    getBoolean,
    setItem,
    removeItem,
    isSupported,
  };
};

const sessionStorage = getSessionStorage();

export default sessionStorage;
