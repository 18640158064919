import Button from "antd/lib/button";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React from "react";
import styled from "styled-components";
import ForbiddenErrorImage from "assets/images/errors/403-image.svg";
import AppNotFoundImage from "assets/images/errors/404-image.svg";
import ServerError500Image from "assets/images/errors/500-image.svg";
import GeneralErrorImage from "assets/images/errors/general-error.svg";
import LoginErrorImage from "assets/images/errors/login-error.svg";

import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { HOME_URL } from "legacy/constants/routes";
import { isOnEmbedRoute } from "utils/embed/messages";
import { VerticalWrapper } from "../../ui/Wrapper";

interface Props {
  title: string;
  errorMessage?: string | React.ReactNode;
  buttonText?: string;
  handleButtonClick?: () => void;
  errorCode?: API_STATUS_CODES | "LOGIN_FAILURE";
  buttonPath?: string;
  image?: string; // when not set, the image will be based on the errorCode
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  hideActions?: boolean;
  showActionsOnEmbedRoute?: boolean; // by default, actions are hidden on embed pages
}

// Most of the errors have this styling anyway
const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${(props) => props.theme.legacy.headerHeight});
  text-align: center;
  padding-top: calc(${(props) => props.theme.legacy.headerHeight});
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
`;

const TextWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const SubText = styled(Text)`
  color: ${(props) => props.theme.colors.GREY_500};
`;

const ErrorComponent = ({
  title,
  errorMessage,
  buttonText,
  handleButtonClick,
  errorCode,
  buttonPath,
  image: overrideImage,
  secondaryButtonAction,
  secondaryButtonText,
  hideActions,
  showActionsOnEmbedRoute,
}: Props) => {
  let image = overrideImage ?? GeneralErrorImage;
  if (!overrideImage) {
    switch (errorCode) {
      case API_STATUS_CODES.RESOURCE_NOT_FOUND:
        image = AppNotFoundImage;
        break;
      case API_STATUS_CODES.SERVER_ERROR:
        image = ServerError500Image;
        break;
      case API_STATUS_CODES.RESOURCE_FORBIDDEN:
        image = ForbiddenErrorImage;
        break;
      case API_STATUS_CODES.TIMEOUT:
        image = GeneralErrorImage;
        break;
      case "LOGIN_FAILURE":
        image = LoginErrorImage;
        break;
      default:
        image = GeneralErrorImage; //we should have covered all the cases and Koala should not show, keeping it here since we do not have a default one
    }
  }
  const showActions =
    !hideActions && (!isOnEmbedRoute() || showActionsOnEmbedRoute);

  return (
    <ErrorWrapper data-test="app-error-container" className="error-wrapper">
      <VerticalWrapper>
        <img src={image} alt="" />
        <TextWrapper>
          <Title level={3}>{title}</Title>
          {errorMessage && (
            <SubText data-test="error-page-subtext">{errorMessage}</SubText>
          )}
        </TextWrapper>
        {showActions && (
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {secondaryButtonText && secondaryButtonAction && (
              <Button type={"ghost"} onClick={secondaryButtonAction}>
                {secondaryButtonText}
              </Button>
            )}
            <Button
              type={"primary"}
              onClick={() =>
                handleButtonClick
                  ? handleButtonClick()
                  : (window.location.pathname = buttonPath ?? HOME_URL)
              }
              data-test="error-component-button"
            >
              {buttonText ? buttonText : "Return Home"}
            </Button>
          </div>
        )}
      </VerticalWrapper>
    </ErrorWrapper>
  );
};

export default ErrorComponent;
