import React from "react";
import styled from "styled-components";
import NoAgentsImage from "assets/images/errors/no-agents-image.svg";
import ErrorComponent from "components/app/Error/ErrorComponent";

const Wrapper = styled.div`
  text-align: center;
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
  height: 100%;
`;

const PageNoAgents = () => {
  return (
    <Wrapper>
      <ErrorComponent
        title="Can’t contact your agent"
        errorMessage={
          <div style={{ maxWidth: 520 }}>
            We’re having trouble connecting to your agent to verify your code.
            Click try again to refresh the page or contact your organization
            administrator. Learn more in our{" "}
            <a
              href={
                "https://docs.superblocks.com/on-premise-agent/manage/troubleshooting"
              }
              target="_blank"
              rel="noreferrer"
            >
              Troubleshooting Guide.
            </a>
          </div>
        }
        image={NoAgentsImage}
        buttonText="Try Again"
        handleButtonClick={() => window.location.reload()}
        secondaryButtonText="Return Home"
        secondaryButtonAction={() => window.location.replace("/")}
      />
    </Wrapper>
  );
};

export default PageNoAgents;
