import { combineReducers } from "redux";

import legacyReducer from "legacy/reducers";

import successfulWriteReducer from "../legacy/reducers/entityReducers/successfulWriteReducer";
import agentsSlice from "./slices/agents";
import apisSlice from "./slices/apis";
import apisV2Slice from "./slices/apisV2/slice";
import { applicationReducer } from "./slices/application";
import availableWidgets from "./slices/availableWidgets";
import commitsSlice from "./slices/commits";
import datasourceSlice from "./slices/datasources";
import deploymentsSlice from "./slices/deployments";
import featureFlags from "./slices/featureFlags";
import { homepageSlice } from "./slices/homepage/slice";
import organizationsSlice from "./slices/organizations";
import repoConnectionsSlice from "./slices/repositories";
import userSlice from "./slices/user";

export default combineReducers({
  legacy: legacyReducer,
  [agentsSlice.name]: agentsSlice.rootReducer,
  [organizationsSlice.name]: organizationsSlice.rootReducer,
  [userSlice.name]: userSlice.rootReducer,
  [apisSlice.name]: apisSlice.rootReducer,
  [apisV2Slice.name]: apisV2Slice.rootReducer,
  [datasourceSlice.name]: datasourceSlice.rootReducer,
  [featureFlags.name]: featureFlags.rootReducer,
  [availableWidgets.name]: availableWidgets.rootReducer,
  [commitsSlice.name]: commitsSlice.rootReducer,
  [repoConnectionsSlice.name]: repoConnectionsSlice.rootReducer,
  [deploymentsSlice.name]: deploymentsSlice.rootReducer,
  application: applicationReducer,
  homepage: homepageSlice.reducer,
  lastSuccessfulWrite: successfulWriteReducer,
});
