import { PayloadAction as RTKPayloadAction } from "@reduxjs/toolkit";
import { Action as ReduxAction } from "redux";

export type Action = ReduxAction<string>;

export type PayloadAction<
  PayloadType,
  ActionName extends string = string,
> = RTKPayloadAction<PayloadType, ActionName> & {
  isParentAction?: boolean;
  isChildAction?: boolean;
};

export interface PayloadActionWithCallId<T = void> extends PayloadAction<T> {
  callId: number;
}

export interface PayloadActionWithMeta<TPayload = void, TMeta = void>
  extends PayloadAction<TPayload> {
  meta: TMeta;
}

export interface ActionDefinitionBase<TPayload> {
  type: string;
  $$payloadType?: TPayload;
  // useSaga helper generates this
  resolveId?: string;
}

export interface ActionDefinition<TPayload>
  extends ActionDefinitionBase<TPayload> {
  create: (payload: TPayload) => PayloadAction<TPayload>;
}

export interface ActionDefinitionWithCallId<TPayload>
  extends ActionDefinitionBase<TPayload> {
  create: (payload: TPayload) => PayloadActionWithCallId<TPayload>;
}

export interface ActionDefinitionWithMeta<TPayload, TMeta>
  extends ActionDefinitionBase<TPayload> {
  create: (
    payload: TPayload,
    meta: TMeta,
  ) => PayloadActionWithMeta<TPayload, TMeta>;
}

export function defineAction<TPayload>(
  scope: string,
  event: string,
): ActionDefinition<TPayload> {
  const type = `${scope}/${event}`;
  const create = (payload: TPayload) => ({ type, payload });

  return { type, create };
}

export function isPayloadAction(
  action: unknown,
): action is PayloadAction<unknown> {
  return Boolean(action && typeof action === "object" && "payload" in action);
}

export function defineActionWithCallId<TPayload>(
  scope: string,
  event: string,
): ActionDefinitionWithCallId<TPayload> {
  let lastCallId = 0;
  const type = `${scope}/${event}`;
  const create = (payload: TPayload) => ({
    type,
    payload,
    callId: ++lastCallId,
  });

  return { type, create };
}

export function defineActionWithMeta<TPayload, TMeta>(
  scope: string,
  event: string,
): ActionDefinitionWithMeta<TPayload, TMeta> {
  const type = `${scope}/${event}`;

  return {
    type,
    create(payload: TPayload, meta: TMeta) {
      return { type, payload, meta };
    },
  };
}

export function isPayloadActionWithMeta(
  action: Action & { payload?: unknown; meta?: unknown },
): action is PayloadActionWithMeta<unknown, { args: unknown; callId: number }> {
  return (
    "meta" in action &&
    typeof action.meta !== "undefined" &&
    isPayloadAction(action)
  );
}
