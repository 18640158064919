import {
  isApplicationUrl,
  isScheduledJobUrl,
  isWorkflowUrl,
} from "@superblocksteam/shared";
import React from "react";
import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { EntityType } from "utils/entity";
import RequestAccess from "./RequestAccess";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${(props) => props.theme.legacy.headerHeight});
  background-color: #fafafa;
  text-align: center;
  padding-top: calc(${(props) => props.theme.legacy.headerHeight});
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
`;

const ResourceForbidden = () => {
  const url = window.location.pathname;
  let entityType = undefined;
  if (isApplicationUrl(url)) {
    entityType = EntityType.APPLICATION;
  } else if (isWorkflowUrl(url)) {
    entityType = EntityType.WORKFLOW;
  } else if (isScheduledJobUrl(url)) {
    entityType = EntityType.SCHEDULED_JOB;
  }
  return (
    <Wrapper>
      {entityType ? (
        <RequestAccess entityType={entityType} />
      ) : (
        <ErrorComponent
          errorCode={API_STATUS_CODES.RESOURCE_FORBIDDEN}
          title="You do not have the permission to access the requested resource."
          errorMessage="Please request access from the owner of the resource."
        />
      )}
    </Wrapper>
  );
};

export default ResourceForbidden;
