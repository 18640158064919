import { ApplicationScope } from "@superblocksteam/shared";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { emptyDataTree } from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { createImmerReducer } from "../createReducer";

export type EvaluatedTreeState = DataTree;

const initialState: EvaluatedTreeState = emptyDataTree();

const evaluatedTreeReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SET_EVALUATED_TREE,
      (
        state: EvaluatedTreeState,
        action: ReduxAction<
          DataTree,
          typeof ReduxActionTypes.SET_EVALUATED_TREE
        >,
      ) => {
        for (const info of action.payload.deletedEntities ?? []) {
          delete state[info.scope][info.name];
        }

        // Warning - this is a performance critical section. Large tree updates
        // can cause significant performance issues, do not modify without profiling
        for (const possibleScope in action.payload) {
          if (possibleScope === "deletedEntities") continue;
          const scope = possibleScope as unknown as ApplicationScope;
          const scopedTree = action.payload[scope];
          if (!scopedTree) continue;
          for (const entityName in scopedTree) {
            state[scope][entityName] = Object.freeze(
              scopedTree[entityName],
            ) as any;
          }
        }
      },
    )
    .addCase(ReduxActionTypes.FETCH_PAGE_INIT, () => initialState)
    .addCase(restartEvaluation, () => initialState)
    .addCase(stopEvaluation, () => initialState),
);

export default evaluatedTreeReducer;
