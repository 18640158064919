import { Button } from "antd";
import styled from "styled-components";

export const HeaderButton = styled(Button)`
  color: ${({ theme }) => theme.colors.OFF_WHITE};
  border: none;
  background-color: ${({ theme }) => theme.colors.GREY_600};
  margin-right: 10px;
  font-size: ${(props) => props.theme.text.sizes.sbHeader};
  font-weight: ${(props) => props.theme.text.weights.sbHeader};
  height: 28px;
  padding: 4px 12px;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.GREY_800};
    color: ${({ theme }) => theme.colors.OFF_WHITE};
  }
`;
