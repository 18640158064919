import { OrgBriefDto, SUPERBLOCKS_ORG_NAME } from "@superblocksteam/shared";
import { Button, List, Modal, Popover, Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spinner } from "components/ui/Spinner";
import { HeaderButton } from "components/ui/headers/Button";
import SearchComponent from "legacy/components/designSystems/default/SearchComponent";
import { scrollbarLight } from "legacy/constants/DefaultTheme";
import { User } from "legacy/constants/userConstants";
import { selectOnlyOrganization } from "store/slices/organizations";
import { callServer, HttpMethod } from "store/utils/client";
import { sendErrorUINotification } from "utils/notification";

type Org = {
  id: string;
  name: string;
};

const StyledSearchComponentWrapper = styled.div`
  .bp5-input-group {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const OrgListWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
  ${scrollbarLight}
`;

const StyledListItem = styled(List.Item)`
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.GREY_100};
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const getAllOrgs = () => {
  return callServer<OrgBriefDto[]>({
    method: HttpMethod.Get,
    url: `v1/management/orgs`,
  });
};

const switchUserOrg = (organizationId: string) => {
  return callServer<User>({
    method: HttpMethod.Post,
    url: `v1/management/switch-org/organization/${organizationId}`,
  });
};

const OrgSwitcher = () => {
  const [allOrgs, setAllOrgs] = useState<OrgBriefDto[]>([]);
  const [isAllOrgsLoading, setIsAllOrgsLoading] = useState(false);
  const organization = useSelector(selectOnlyOrganization);

  const fetchAllOrgs = useCallback(async () => {
    try {
      setIsAllOrgsLoading(true);
      const orgs = await getAllOrgs();
      setAllOrgs(orgs);
    } catch (error: any) {
      sendErrorUINotification({
        message: `Failed to fetch all orgs. ${error?.message}`,
      });
    }
    setIsAllOrgsLoading(false);
  }, []);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const onSearch = useCallback((value: string) => {
    setSearchKeyword(value);
  }, []);

  const filteredOrgs: Org[] = useMemo(() => {
    return allOrgs?.filter((org) =>
      org.name.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase()),
    );
  }, [allOrgs, searchKeyword]);

  const superblocksOrg = useMemo(
    () => allOrgs.find((org) => org.name === SUPERBLOCKS_ORG_NAME),
    [allOrgs],
  );

  const onSwitch = useCallback(async (org: Org) => {
    try {
      await switchUserOrg(org.id);
      window.location.reload();
    } catch (error: any) {
      sendErrorUINotification({
        message: `Failed to switch org to ${org.name}. ${error?.message}`,
      });
    }
  }, []);

  const onReset = useCallback(() => {
    if (superblocksOrg?.id) {
      onSwitch(superblocksOrg);
    }
  }, [onSwitch, superblocksOrg]);

  const renderOrgList = useCallback(
    (org: Org) => (
      <StyledListItem
        onClick={() => {
          Modal.confirm({
            title: `Are you sure you want to switch to ${org.name}?`,
            icon: <></>,
            okText: "Confirm",
            okType: "primary",
            onOk: () => {
              onSwitch(org);
            },
            zIndex: 1040, //higher than the popover
          });
        }}
      >
        {org.name}
      </StyledListItem>
    ),
    [onSwitch],
  );

  const switchOrgContent = (
    <div>
      <StyledRow>
        <div>
          <b>Current Org</b>: {organization.name}
        </div>
        <ButtonWrapper>
          <Tooltip title={`Go back to ${SUPERBLOCKS_ORG_NAME}`}>
            <Button type="primary" onClick={onReset}>
              Reset
            </Button>
          </Tooltip>
        </ButtonWrapper>
      </StyledRow>
      <StyledSearchComponentWrapper>
        <SearchComponent
          dataTest="orgs-search"
          onSearch={onSearch}
          value={""}
          placeholder={"Search Orgs"}
        />
      </StyledSearchComponentWrapper>
      <Spinner spinning={isAllOrgsLoading}>
        <OrgListWrapper>
          <List
            locale={{ emptyText: "No organizations found" }}
            size="small"
            bordered={true}
            dataSource={filteredOrgs}
            renderItem={renderOrgList}
          />
        </OrgListWrapper>
      </Spinner>
    </div>
  );

  return (
    <Popover trigger="click" content={switchOrgContent}>
      <HeaderButton onClick={() => fetchAllOrgs()}>Switch Org</HeaderButton>
    </Popover>
  );
};

export default OrgSwitcher;
