import {
  ApplicationScope,
  CachedData6,
  PageDSL8,
} from "@superblocksteam/shared";
import { set } from "lodash";
import {
  renameCustomEvents,
  renameEmbedProps,
  renameWidgets,
  setSingleWidget,
} from "legacy/actions/controlActions";
import {
  initCanvasLayout,
  updateCachedData,
  updateLayout,
  updateLayoutPosition,
  updatePartialLayout,
} from "legacy/actions/pageActions";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { EmbedProperty } from "legacy/constants/EmbeddingConstants";
import { EventDefinition } from "legacy/constants/EventConstants";
import {
  ReduxActionTypes,
  ReduxAction,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID, WidgetType } from "legacy/constants/WidgetConstants";
import {
  overwriteScopedStateVars,
  renameStateVars,
} from "store/slices/application/stateVars/slice";
import { AppTimersMap } from "store/slices/application/timers/TimerConstants";
import { overwriteScopedTimers } from "store/slices/application/timers/slice";
import { renameTimers } from "store/slices/application/timers/timerActions";
import { createImmerReducer } from "../createReducer";
import type { WidgetProps } from "legacy/widgets";

// The backend types are missing our union types like widget type and event type
// so we have to re-assert the types here
export type ReduxPageType = Omit<
  PageDSL8,
  "type" | "children" | "apis" | "stateVars" | "timers" | "embedding" | "events"
> & {
  apis: {
    apiMap: Record<string, ApiInfo>;
  };
  timers: {
    timerMap: AppTimersMap;
  };
  embedding: {
    propertyMap: Record<string, EmbedProperty>;
    emittedEvents?: Record<string, boolean>;
    triggerableEvents?: Record<string, boolean>;
  };
  events?: {
    eventMap: Record<string, EventDefinition>;
  };
  cachedData?: CachedData6;
} & FlattenedWidgetProps;

export type CanvasWidgetsReduxState = Record<string, FlattenedWidgetProps> & {
  [PAGE_WIDGET_ID]?: ReduxPageType;
};

export type FlattenedWidgetProps =
  | Omit<WidgetProps, "children"> & {
      type: WidgetType;
      children?: string[];
    };

const initialState: CanvasWidgetsReduxState = {};

const canvasWidgetsReducer = createImmerReducer<CanvasWidgetsReduxState>(
  initialState,
  (builder) =>
    builder
      .addCase(initCanvasLayout, (state: CanvasWidgetsReduxState, action) => {
        // Because of the assignment, state must be returned
        state = action.payload.widgets;
        for (const widgetId in action.payload.widgets) {
          state[widgetId] = {
            ...action.payload.widgets[widgetId],
            widgetLastChange: new Date(),
          };
        }
        return state;
      })
      .addCase(updateLayout, (state, action) => {
        // Must return state here
        state = action.payload.widgets;
        for (const widgetId in action.payload.widgets) {
          state[widgetId] = {
            ...action.payload.widgets[widgetId],
            widgetLastChange: new Date(),
          };
        }
        return state;
      })
      .addCase(updatePartialLayout, (state, action) => {
        for (const widgetId in action.payload.widgets) {
          state[widgetId] = {
            ...action.payload.widgets[widgetId],
            widgetLastChange: new Date(),
          };
        }
        // state is not returned for efficiency
        // https://redux-toolkit.js.org/usage/immer-reducers#mutating-and-returning-state
      })
      .addCase(updateLayoutPosition, (state, action) => {
        for (const widgetId in action.payload.widgets) {
          const currentWidget = state[widgetId];

          if (!currentWidget) {
            console.warn(
              `Widget(${widgetId}) not found while updating position`,
            );
            continue;
          }

          const positions = action.payload.widgets[widgetId];
          Object.keys(positions).forEach((key) => {
            (currentWidget as any)[key] =
              positions[key as keyof typeof positions];
          });
          currentWidget.widgetLastChange = new Date();
        }
        // state is not returned for efficiency
        // https://redux-toolkit.js.org/usage/immer-reducers#mutating-and-returning-state
      })
      .addCase(updateCachedData, (state, action) => {
        if (state[PAGE_WIDGET_ID]) {
          (state[PAGE_WIDGET_ID] as ReduxPageType).cachedData = {
            ...(state[PAGE_WIDGET_ID].cachedData ?? {}),
            ...action.payload.cachedData,
          };
        }
        // state is not returned for efficiency
        // https://redux-toolkit.js.org/usage/immer-reducers#mutating-and-returning-state
      })
      .addCase(setSingleWidget, (state, action) => {
        const widgetId = action.payload.widgetId;
        state[widgetId] = action.payload.newWidget;
        state[widgetId].widgetLastChange = new Date();
      })
      .addCase(renameWidgets, (state, action) => {
        action.payload.updates.forEach(
          ({ widgetId, propertyName, propertyValue }) => {
            set(state[widgetId], propertyName, propertyValue);
            state[widgetId].widgetLastChange = new Date();
          },
        );
      })
      .addCase(
        renameTimers,
        (
          state,
          action: ReduxAction<ReturnType<typeof renameTimers>["payload"]>,
        ) => {
          if (
            action.payload.updates.some(
              (update) => update.scope === ApplicationScope.PAGE,
            )
          ) {
            const mainContainer = state[PAGE_WIDGET_ID];
            if (mainContainer) mainContainer.widgetLastChange = new Date();
          }
        },
      )
      .addCase(overwriteScopedTimers, (state, action) => {
        if (action.payload.scope === ApplicationScope.PAGE) {
          const mainContainer = state[PAGE_WIDGET_ID];
          if (mainContainer) mainContainer.widgetLastChange = new Date();
        }
      })
      .addCase(renameStateVars, (state, action) => {
        const mainContainer = state[PAGE_WIDGET_ID];
        if (mainContainer) mainContainer.widgetLastChange = new Date();
      })
      .addCase(overwriteScopedStateVars, (state, action) => {
        if (action.payload.scope === ApplicationScope.PAGE) {
          const mainContainer = state[PAGE_WIDGET_ID];
          if (mainContainer) mainContainer.widgetLastChange = new Date();
        }
      })
      .addCase(renameEmbedProps, (state, action) => {
        action.payload.updates.forEach(
          ({ embedPropId, propertyName, propertyValue }) => {
            const mainContainer = state[PAGE_WIDGET_ID];
            if (mainContainer && "embedding" in mainContainer) {
              set(
                mainContainer.embedding.propertyMap[embedPropId],
                propertyName,
                propertyValue,
              );
              mainContainer.widgetLastChange = new Date();
            }
          },
        );
        return state;
      })
      .addCase(renameCustomEvents, (state, action) => {
        action.payload.updates.forEach(
          ({ eventId, propertyName, propertyValue }) => {
            const mainContainer = state[PAGE_WIDGET_ID];
            if (
              mainContainer &&
              "events" in mainContainer &&
              mainContainer.events?.eventMap
            ) {
              set(
                mainContainer.events?.eventMap[eventId],
                propertyName,
                propertyValue,
              );
              mainContainer.widgetLastChange = new Date();
            }
          },
        );
        return state;
      })
      .addCase(ReduxActionTypes.RESET_WIDGETS, () => {
        return {};
      }),
);

export default canvasWidgetsReducer;
